import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PublicLayoutComponent } from '@moose/layouts/public/public-layout.component';
import { ErrorPageComponent } from '@moose/layouts/public/error-page/error-page.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    loadChildren: () => import('@moose/layouts/login/login-layout.module').then((m) => m.LoginLayoutModule),
  },
  {
    path: 'manage',
    loadChildren: () => import('@moose/layouts/admin/admin-layout.module').then((m) => m.AdminLayoutModule),
  },
  {
    path: '',
    component: PublicLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./public/homepage/homepage.module').then((m) => m.HomepageModule),
      },
      {
        path: 'user-agreement',
        loadChildren: () => import('./public/user-agreement/user-agreement.module').then((m) => m.UserAgreementModule),
      },
      {
        path: 'info/manufacturers',
        loadChildren: () =>
          import('./public/info-manufacturers/info-manufacturers.module').then((m) => m.InfoManufacturersModule),
      },
      {
        path: 'info/retailers',
        loadChildren: () => import('./public/info-retailers/info-retailers.module').then((m) => m.InfoRetailersModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('@moose/public/contact/contact-public.module').then((m) => m.ContactPublicModule),
      },
      {
        path: 'ontario-manufacturing-day',
        loadChildren: () =>
          import('./public/ontario-manufacturing-day/ontario-manufacturing-day.module').then(
            (m) => m.OntarioManufacturingDayModule
          ),
      },
      {
        path: 'partners',
        loadChildren: () => import('./public/partners/partners.module').then((m) => m.PartnersModule),
      },
      {
        path: 'manufacturers',
        loadChildren: () => import('./public/manufacturers/manufacturers.module').then((m) => m.ManufacturersModule),
      },
      {
        path: 'blog',
        loadChildren: () => import('./public/blog/blog-post-public.module').then((m) => m.BlogPostPublicModule),
      },
      {
        path: 'consumers',
        loadChildren: () => import('./public/consumers/consumers.module').then((m) => m.ConsumersModule),
      },
      {
        path: 'retailers',
        loadChildren: () => import('./public/retailers/retailers.module').then((m) => m.RetailersModule),
      },
      {
        path: 'search-results',
        loadChildren: () => import('./public/search-results/search-results.module').then((m) => m.SearchResultsModule),
      },
      {
        path: 'register',
        loadChildren: () => import('./public/register/register.module').then((m) => m.RegisterModule),
      },
      {
        path: 'register-retailer',
        loadChildren: () =>
          import('./public/register-retailers/register-retailers.module').then((m) => m.RegisterRetailersModule),
      },
      {
        path: 'account/verify',
        loadChildren: () => import('./public/verification/verification.module').then((m) => m.VerificationModule),
      },
      {
        path: 'register-products',
        loadChildren: () =>
          import('./public/register-manufacturer/register-manufacturer.module').then(
            (m) => m.RegisterManufacturerModule
          ),
      },
      {
        path: 'register-manufacturer',
        loadChildren: () =>
          import('./public/register-manufacturer/register-manufacturer.module').then(
            (m) => m.RegisterManufacturerModule
          ),
      },
      {
        path: 'companies',
        loadChildren: () => import('./public/companies/companies.module').then((m) => m.CompaniesModule),
      },
      {
        path: '',
        loadChildren: () => import('./public/categories/categories.module').then((m) => m.CategoriesModule),
      },
      {
        path: 'register-manufacturer',
        loadChildren: () =>
          import('./public/register-manufacturer/register-manufacturer.module').then(
            (m) => m.RegisterManufacturerModule
          ),
      },
      {
        path: '',
        loadChildren: () => import('./public/products/products.module').then((m) => m.ProductsModule),
      },
      {
        path: 'reset-password',
        loadChildren: () =>
          import('./public/credential-reset/reset-password/reset-password.module').then((m) => m.ResetPasswordModule),
      },
      {
        path: 'new-password',
        loadChildren: () =>
          import('./public/credential-reset/new-password/new-password.module').then((m) => m.NewPasswordModule),
      },
      {
        path: '**',
        component: ErrorPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
