import { Injectable } from '@angular/core';
import { AbstractEntityService } from '@moose/domain/abstract/abstract-entity.service';
import { HttpClient } from '@angular/common/http';
import { RetailOutlet } from './retail-outlet';
import { ProductRetailOutlet } from '@moose/domain/product/product-retail-outlet';
import { PagedEntities } from '@moose/domain/abstract/paged-entities';
import { User } from '../user/user';
import { Observable } from 'rxjs';

@Injectable()
export class RetailOutletService extends AbstractEntityService<RetailOutlet> {
  constructor(http: HttpClient) {
    super('/api/retail-outlets', http);
  }

  updateLogo(outlet: RetailOutlet, logoFile: { logoBase64: string; logoFilename: string }) {
    return this.http.post(this.apiUrl + this.basePath + '/' + outlet.id + '/logo', JSON.stringify(logoFile));
  }

  emailAvailable(emailAddress: string) {
    return this.http.post<boolean>(this.apiUrl + this.basePath + '/email-available', emailAddress);
  }

  getBySlug(slug: string) {
    return this.http.get<RetailOutlet>(this.apiUrl + this.basePath + '/slug/' + slug);
  }

  getProducts(id: number) {
    return this.http.get<Array<ProductRetailOutlet>>(this.apiUrl + this.basePath + '/' + id + '/products');
  }

  getUsers(id: number) {
    return this.http.get<Array<User>>(this.apiUrl + this.basePath + '/' + id + '/users');
  }

  assignProducts(retailOutletId: number, productsIds: Array<number>) {
    return this.http.post(this.apiUrl + this.basePath + '/' + retailOutletId + '/products', productsIds);
  }

  unAssignProducts(retailOutletId: number, productsIds: number[]) {
    return this.http.post(this.apiUrl + this.basePath + '/' + retailOutletId + '/products/unassign', productsIds);
  }

  search(query: string, pageNum: number): Observable<PagedEntities<RetailOutlet>> {
    return this.http.get<PagedEntities<RetailOutlet>>(
      this.apiUrl + '/api/search/retail-outlets?query=' + query + '&pageNum=' + pageNum
    );
  }
}
