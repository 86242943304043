import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SearchProductsResult } from '../search-results/search-products-result';

@Injectable()
export class SearchService {
  protected apiUrl = environment.apiHost + '/api/search';

  constructor(private http: HttpClient) {
    this.http = http;
  }

  public getSuggestions(query: string): Observable<SearchProductsResult> {
    let url = this.apiUrl + '/products?';
    const parts = [];
    parts.push('name=' + query);
    parts.push('limit=5');
    parts.push('offset=0');
    url += parts.join('&');
    return this.http.get<SearchProductsResult>(url);
  }

  public search(query: string, limit: number, offset: number, filters?: Map<string, string[]>) {
    let url = this.apiUrl + '/products?';
    const parts = [];
    if (filters && filters.size > 0) {
      filters.forEach((values, key) => {
        values.forEach((v) => {
          parts.push(key + '=' + encodeURIComponent(v));
        });
      });
    }
    parts.push('name=' + query);
    parts.push('limit=' + (limit ? limit + '' : '12'));
    parts.push('offset=' + (offset ? offset + '' : '0'));
    url += parts.join('&');
    return this.http.get<SearchProductsResult>(url);
  }
}
