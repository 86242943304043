import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService } from '@moose/domain/abstract/abstract-entity.service';
import { Observable, of } from 'rxjs';
import { Category } from '@moose/domain/category/category';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class CategoryService extends AbstractEntityService<Category> {
  public static industryTree: Category[] = [
    {
      id: 2,
      name: $localize`Industrial & Automotive`,
      type: 'INDUSTRY',
      children: [
        {
          id: 40,
          name: $localize`Tools & Equipment`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 2,
          slug: 'tools-equipment',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 41,
          name: $localize`Oils & Fluids`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 2,
          slug: 'oils-fluids',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 42,
          name: $localize`Components`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 2,
          slug: 'components',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 43,
          name: $localize`Vehicles`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 2,
          slug: 'vehicles',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 53,
          name: $localize`Printing & Design`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 2,
          slug: 'printing-design',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 54,
          name: $localize`Aerospace & Defense`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 2,
          slug: 'aerospace-defense',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
      ],
      parentCategoryId: null,
      slug: 'industrial-automotive',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 3,
      name: $localize`Clothing & Accessories`,
      type: 'INDUSTRY',
      children: [
        {
          id: 29,
          name: $localize`Men`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 3,
          slug: 'men',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 30,
          name: $localize`Women`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 3,
          slug: 'women',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 31,
          name: $localize`Accessories`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 3,
          slug: 'accessories',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 32,
          name: $localize`Kids`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 3,
          slug: 'kids',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 33,
          name: $localize`Industry Equipment`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 3,
          slug: 'industry-equipment',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
      ],
      parentCategoryId: null,
      slug: 'clothing-accessories',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 4,
      name: $localize`Food & Beverage`,
      type: 'INDUSTRY',
      children: [
        {
          id: 13,
          name: $localize`Beverages`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'beverages',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 15,
          name: $localize`Sauces, Spreads & Dips`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'sauces-spreads-dips',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 16,
          name: $localize`Snacks`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'snacks',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 17,
          name: $localize`Frozen Food`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'frozen-food',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 18,
          name: $localize`Spices, Herbs & Seasonings`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'spices-herbs-seasonings',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 19,
          name: $localize`Pantry`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'pantry',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 20,
          name: $localize`Sweets & Treats`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'sweets-treats',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 21,
          name: $localize`Oils, Vinegars & Dressings`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'oils-vinegars-dressings',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 22,
          name: $localize`Meat & Seafood`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'meat-seafood',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 23,
          name: $localize`Dairy & Eggs`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'dairy-eggs',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 24,
          name: $localize`Alcohol`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'alcohol',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 14,
          name: $localize`Jams & Honey`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'jams-honey',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 25,
          name: $localize`Fruits & Vegetables`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 4,
          slug: 'fruits-vegetables',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
      ],
      parentCategoryId: null,
      slug: 'food-beverage',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 5,
      name: $localize`Home, Office & Garden`,
      type: 'INDUSTRY',
      children: [
        {
          id: 34,
          name: $localize`Furniture`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 5,
          slug: 'furniture',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 35,
          name: $localize`Garden & Outdoor`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 5,
          slug: 'garden-outdoor',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 36,
          name: $localize`Art & Décor`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 5,
          slug: 'art-decor',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 37,
          name: $localize`Household Supplies`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 5,
          slug: 'household-supplies',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 39,
          name: $localize`Appliances`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 5,
          slug: 'appliances',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 38,
          name: $localize`Art & Office Supplies`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 5,
          slug: 'art-office-supplies',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
      ],
      parentCategoryId: null,
      slug: 'home-office-garden',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 6,
      name: $localize`Construction & Renovations`,
      type: 'INDUSTRY',
      children: [
        {
          id: 44,
          name: $localize`Supplies`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 6,
          slug: 'supplies',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 45,
          name: $localize`Home Renovations`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 6,
          slug: 'home-renovations',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 46,
          name: $localize`Commercial`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 6,
          slug: 'commercial',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
      ],
      parentCategoryId: null,
      slug: 'construction-renovations',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 7,
      name: $localize`Health & Medical`,
      type: 'INDUSTRY',
      children: [
        {
          id: 49,
          name: $localize`PPE`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 7,
          slug: 'ppe',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 50,
          name: $localize`Sanitizers`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 7,
          slug: 'sanitizers',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 51,
          name: $localize`Devices & Equipment`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 7,
          slug: 'devices-equipment',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 52,
          name: $localize`Supplements`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 7,
          slug: 'supplements',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
      ],
      parentCategoryId: null,
      slug: 'health-medical',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 8,
      name: $localize`Sports, Games & Recreation`,
      type: 'INDUSTRY',
      children: [],
      parentCategoryId: null,
      slug: 'sports-recreation',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 9,
      name: $localize`Pet Supplies`,
      type: 'INDUSTRY',
      children: [],
      parentCategoryId: null,
      slug: 'pet-supplies',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 10,
      name: $localize`Miscellaneous`,
      type: 'INDUSTRY',
      children: [],
      parentCategoryId: null,
      slug: 'miscellaneous',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 11,
      name: $localize`Technology`,
      type: 'INDUSTRY',
      children: [
        {
          id: 47,
          name: $localize`IT & Software`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 11,
          slug: 'it-software',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 48,
          name: $localize`Electronics`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 11,
          slug: 'electronics',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
      ],
      parentCategoryId: null,
      slug: 'technology',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
    {
      id: 12,
      name: $localize`Beauty & Personal Care`,
      type: 'INDUSTRY',
      children: [
        {
          id: 26,
          name: $localize`Skin Care`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 12,
          slug: 'skin-care',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 27,
          name: $localize`Personal Care`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 12,
          slug: 'personal-care',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
        {
          id: 28,
          name: $localize`Hair Care`,
          type: 'CATEGORY',
          children: [],
          parentCategoryId: 12,
          slug: 'hair-care',
          state: 'ACTIVE',
          createdAt: new Date(),
        },
      ],
      parentCategoryId: null,
      slug: 'beauty-personal-care',
      state: 'ACTIVE',
      createdAt: new Date(),
    },
  ];

  public static allCategories = [
    { id: 2, name: $localize`Industrial & Automotive` },
    { id: 3, name: $localize`Clothing & Accessories` },
    { id: 4, name: $localize`Food & Beverage` },
    { id: 5, name: $localize`Home, Office & Garden` },
    { id: 6, name: $localize`Construction & Renovations` },
    { id: 7, name: $localize`Health & Medical` },
    { id: 8, name: $localize`Sports, Games & Recreation` },
    { id: 9, name: $localize`Pet Supplies` },
    { id: 10, name: $localize`Miscellaneous` },
    { id: 11, name: $localize`Technology` },
    { id: 12, name: $localize`Beauty & Personal Care` },
    { id: 13, name: $localize`Beverages` },
    { id: 14, name: $localize`Jams & Honey` },
    { id: 15, name: $localize`Sauces, Spreads & Dips` },
    { id: 16, name: $localize`Snacks` },
    { id: 17, name: $localize`Frozen Food` },
    { id: 18, name: $localize`Spices, Herbs & Seasonings` },
    { id: 19, name: $localize`Pantry` },
    { id: 20, name: $localize`Sweets & Treats` },
    { id: 21, name: $localize`Oils, Vinegars & Dressings` },
    { id: 22, name: $localize`Meat & Seafood` },
    { id: 23, name: $localize`Dairy & Eggs` },
    { id: 24, name: $localize`Alcohol` },
    { id: 25, name: $localize`Fruits & Vegetables` },
    { id: 26, name: $localize`Skin Care` },
    { id: 27, name: $localize`Personal Care` },
    { id: 28, name: $localize`Hair Care` },
    { id: 29, name: $localize`Men` },
    { id: 30, name: $localize`Women` },
    { id: 31, name: $localize`Accessories` },
    { id: 32, name: $localize`Kids` },
    { id: 33, name: $localize`Industry Equipment` },
    { id: 34, name: $localize`Furniture` },
    { id: 35, name: $localize`Garden & Outdoor` },
    { id: 36, name: $localize`Art & Décor` },
    { id: 37, name: $localize`Household Supplies` },
    { id: 38, name: $localize`Art & Office Supplies` },
    { id: 39, name: $localize`Appliances` },
    { id: 40, name: $localize`Tools & Equipment` },
    { id: 41, name: $localize`Oils & Fluids` },
    { id: 42, name: $localize`Components` },
    { id: 43, name: $localize`Vehicles` },
    { id: 44, name: $localize`Supplies` },
    { id: 45, name: $localize`Home Renovations` },
    { id: 46, name: $localize`Commercial` },
    { id: 47, name: $localize`IT & Software` },
    { id: 48, name: $localize`Electronics` },
    { id: 49, name: $localize`PPE` },
    { id: 50, name: $localize`Sanitizers` },
    { id: 51, name: $localize`Devices & Equipment` },
    { id: 52, name: $localize`Supplements` },
    { id: 53, name: $localize`Printing & Design` },
    { id: 54, name: $localize`Aerospace & Defense` },
  ];

  constructor(http: HttpClient) {
    super('/api/categories', http);
  }

  getIndustryById(id: number): Observable<Category> {
    return of(...CategoryService.industryTree).pipe(
      filter((c) => {
        return c.id === +id && c.type === 'INDUSTRY';
      })
    );
  }

  getCategoryBySlug(slug: any): Observable<Category> {
    return of(...CategoryService.industryTree).pipe(filter((c) => c.slug === slug));
  }

  getChildren(id: number): Observable<Array<Category>> {
    return this.getIndustryById(id).pipe(map((c) => c.children || []));
  }

  getIndustry(id: number): Observable<Array<Category>> {
    return this.get('/' + id + '/children');
  }

  getIndustries(): Observable<Array<Category>> {
    return of(CategoryService.industryTree);
  }
}
