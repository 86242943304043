import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable()
export class UIHelperService {
  constructor(private router: Router, private title: Title) {
    router.events.subscribe((val) => {
      title.setTitle('Support Ontario Made');
    });
    title.setTitle('Support Ontario Made');
  }

  setTitle(value: string) {
    this.title.setTitle(value + ' | Support Ontario Made');
  }
}
