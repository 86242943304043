import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SearchService } from '../search.service';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NavigationEnd, Router } from '@angular/router';
import { SearchProductsResult } from '../../search-results/search-products-result';
import { Product } from '@moose/domain/product/product';
import { ProductHit } from '../../search-results/product-hit';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
})
export class SearchBoxComponent implements OnInit, OnDestroy {
  @ViewChild('inputEl')
  inputEl!: ElementRef;

  @Input()
  style = 'small';

  @Input()
  xsViewport = false;

  @HostBinding('style.width') width?: string;

  @Output()
  private searchInXsViewport = new EventEmitter();

  showInputInXs = false;
  result!: SearchProductsResult;

  public input!: string;
  public showPopup = false;
  public activeSuggestion = -1;
  public totalSuggestions = 0;
  public inputChanged: Subject<string> = new Subject<string>();
  private inputChangeSubscription!: Subscription;

  constructor(private service: SearchService, private router: Router, private elementRef: ElementRef) {}

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    // hide open menu when on global click outside the menu
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showPopup = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    if (this.xsViewport) {
      this.searchInXsViewport.emit(false);
    }
    this.inputEl.nativeElement.style.width = null;
    this.width = '100%';
    this.showInputInXs = false;
  }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      // see also
      if (val instanceof NavigationEnd) {
        if (!val.url.startsWith('/search-results')) {
          this.input = '';
        }
      }
    });
    this.xsViewport = window.innerWidth <= 479;
    this.inputChangeSubscription = this.inputChanged
      // limit number of processes model changes
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((term) => {
        this.input = term;
        this.suggest();
      });
  }

  ngOnDestroy() {
    this.inputChangeSubscription.unsubscribe();
  }

  public suggest() {
    if (this.input.length > 2) {
      this.service.getSuggestions(this.input).subscribe((res) => {
        this.result = res;
        this.activeSuggestion = -1;
        this.totalSuggestions = this.result.hits.length + this.result.suggestions.length;
        if (this.totalSuggestions > 0) {
          this.showPopup = true;
        }
      });
    }
  }

  highlight(value: string) {
    return value.replace(new RegExp(this.input, 'gi'), (match) => {
      return '<span class="unibox__highlight">' + match + '</span>';
    });
  }

  inputKeyboardEvent(event: KeyboardEvent) {
    // cycle through suggestions when pressing arrow keys
    if (event.key === 'ArrowDown') {
      if (this.activeSuggestion + 1 > this.totalSuggestions) {
        this.activeSuggestion = 0;
      } else {
        this.activeSuggestion++;
      }
    }
    if (event.key === 'ArrowUp') {
      if (this.activeSuggestion - 1 >= 0) {
        this.activeSuggestion--;
      } else {
        this.activeSuggestion = this.totalSuggestions - 1;
      }
    }

    // handle suggestion enter key press
    if (event.key === 'Enter') {
      if (this.activeSuggestion === -1) {
        this.goToQuerySuggestion(this.inputEl.nativeElement.value);
        return;
      }
      if (this.activeSuggestion > -1 && this.activeSuggestion < this.result.suggestions.length) {
        // replace search term with query suggestion chosen
        if (this.result?.hits && this.result.suggestions[this.activeSuggestion]) {
          this.goToQuerySuggestion(this.result.suggestions[this.activeSuggestion]);
        }
      } else {
        if (this.result?.hits) {
          this.gotToSearchSuggestion(this.result.hits[this.activeSuggestion]);
        }
      }
    }
  }

  goToQuerySuggestion(querySuggestion: string) {
    if (querySuggestion) {
      this.showPopup = false;
      this.input = querySuggestion;
      this.router.navigateByUrl('/search-results?query=' + querySuggestion);
    }
  }

  gotToSearchSuggestion(product: ProductHit) {
    if (product) {
      this.showPopup = false;
      this.router.navigateByUrl(this.productRoute(product));
    }
  }

  expandInputInXs() {
    if (this.xsViewport) {
      this.inputEl.nativeElement.style.width = '100%';
      this.width = '100%';
      this.searchInXsViewport.emit(true);
      this.showInputInXs = true;
    }
  }

  collapseInputInXs() {
    if (this.xsViewport) {
      this.inputEl.nativeElement.style.width = '0';
      this.searchInXsViewport.emit(false);
      this.showInputInXs = false;
      this.showPopup = false;
    }
  }

  productRoute(product: ProductHit) {
    return Product.routeForSlug(product.slug);
  }

  productImage(product: ProductHit) {
    return Product.imageUrlForProductHit(product);
  }
}
