import { Injectable } from '@angular/core';
import { AbstractEntityService } from '@moose/domain/abstract/abstract-entity.service';
import { HttpClient } from '@angular/common/http';
import { RetailOutlet } from './retail-outlet';
import { RouteParams } from '@moose/domain/abstract/route-params';
import { Observable } from 'rxjs';
import { PagedEntities } from '@moose/domain/abstract/paged-entities';
import { ProductRetailOutlet } from '@moose/domain/product/product-retail-outlet';

@Injectable()
export class ProductRetailOutletService extends AbstractEntityService<RetailOutlet> {
  constructor(http: HttpClient) {
    super('/api/product-retail-outlets', http);
  }

  declineProducts(ids: Array<number>) {
    return this.http.post(this.apiUrl + this.basePath + '/decline', ids);
  }

  confirmProducts(ids: Array<number>) {
    return this.http.post(this.apiUrl + this.basePath + '/confirm', ids);
  }

  getByUserCompany(routeParams: RouteParams): Observable<PagedEntities<ProductRetailOutlet>> {
    return this.http.get<PagedEntities<ProductRetailOutlet>>(
      this.apiUrl + this.basePath + '/by-user-company' + routeParams.toServiceQuery()
    );
  }

  getByUserRetailOutlet(routeParams: RouteParams): Observable<PagedEntities<ProductRetailOutlet>> {
    return this.http.get<PagedEntities<ProductRetailOutlet>>(
      this.apiUrl + this.basePath + '/by-user-retail-outlet' + routeParams.toServiceQuery()
    );
  }
}
