import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePipe } from '@moose/shared/pipes/date.pipe';
import { JoinPipe } from '@moose/shared/pipes/join.pipe';
import { ObjectKeysPipe } from '@moose/shared/pipes/object-keys.pipe';
import { ErrorMessagePipe } from '@moose/shared/pipes/error-message.pipe';
import { ErrorMessagesComponent } from '@moose/shared/components/error-messages.component';
import { SafeHtmlPipe } from '@moose/shared/pipes/safe-html.pipe';
import { UIHelperService } from '@moose/shared/services/ui-helper.service';
import { HumanizePipe } from '@moose/shared/pipes/humanize.pipe';
import { SelectLabelPipe } from '@moose/shared/pipes/select-label.pipe';
import { CustomFieldPipe } from '@moose/shared/pipes/custom-field.pipe';

@NgModule({
  exports: [
    DatePipe,
    JoinPipe,
    ObjectKeysPipe,
    ErrorMessagePipe,
    SafeHtmlPipe,
    ErrorMessagesComponent,
    HumanizePipe,
    SelectLabelPipe,
    CustomFieldPipe,
  ],
  declarations: [
    DatePipe,
    JoinPipe,
    ObjectKeysPipe,
    HumanizePipe,
    ErrorMessagePipe,
    SafeHtmlPipe,
    SelectLabelPipe,
    ErrorMessagesComponent,
    CustomFieldPipe,
  ],
  providers: [UIHelperService],
  imports: [CommonModule],
})
export class SharedModule {}
