import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchBoxComponent } from './components/search-box.component';
import { FormsModule } from '@angular/forms';
import { SearchService } from './search.service';

@NgModule({
  declarations: [SearchBoxComponent],
  imports: [CommonModule, FormsModule],
  providers: [SearchService],
  exports: [SearchBoxComponent],
})
export class SearchModule {}
