<div class="section cc-subscribe-form">
  <div #mailSubscriptionForm id="Newsletter" class="container cc-subscription-form" style="padding: unset;">
<!--    <div class="heading-jumbo-small" i18n="Section header">Sign up for Ontario Made updates</div>-->
<!--    <div-->
<!--      class="paragraph-light cc-subscribe-paragraph stretch"-->
<!--      i18n="Section details. Explains the purpose of this section."-->
<!--    >-->
<!--      Sign up to receive e-mail updates on products made in Ontario-->
<!--    </div>-->
<!--    <div class="form-block w-form">-->
<!--      <form-->
<!--        [formGroup]="form"-->
<!--        (ngSubmit)="onSubmit()"-->
<!--        id="wf-form-Newsletter-Subscription-Form"-->
<!--        name="wf-form-Newsletter-Subscription-Form"-->
<!--        data-name="Newsletter Subscription Form"-->
<!--        class="subscribe-form"-->
<!--      >-->
<!--        <input-->
<!--          type="email"-->
<!--          formControlName="email"-->
<!--          class="text-field cc-subscribe-text-field w-input"-->
<!--          maxlength="256"-->
<!--          name="Email"-->
<!--          data-name="Email"-->
<!--          i18n-placeholder="Placeholder for am email input field"-->
<!--          placeholder="Enter your email"-->
<!--          id="Email-3"-->
<!--          required=""-->
<!--        />-->

<!--        <input-->
<!--          type="submit"-->
<!--          value="Submit"-->
<!--          i18n-data-wait="Message that is displayed on the button while the user waits for the page to load."-->
<!--          data-wait="Please wait..."-->
<!--          class="button w-button"-->
<!--        />-->
<!--      </form>-->
<!--      <div-->
<!--        style="color: red; float: left"-->
<!--        *ngIf="form.get('email')?.dirty && form.get('email')?.errors?.required"-->
<!--        i18n="Notice to the user they must enter an email"-->
<!--      >-->
<!--        *Email is required-->
<!--        <br />-->
<!--      </div>-->
<!--      <br />-->
<!--      <div [ngStyle]="{ display: success ? 'block' : 'none' }" class="status-message w-form-done">-->
<!--        <div i18n="Success message for the newsletter signup">Thank you! Your submission has been received!</div>-->
<!--      </div>-->
<!--      <br />-->
<!--      <div [ngStyle]="{ display: error ? 'block' : 'none' }" class="status-message cc-error-message w-form-fail">-->
<!--        <div i18n="Error message for the newsletter signup">Oops! Something went wrong while submitting the form.</div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</div>
<div class="section">
  <div class="container footer-margin">
    <div class="footer">
      <div class="footer-logo-container">
        <a href="../../../../index.html" aria-current="page" class="link-block footer w-inline-block w--current">
          <img src="assets/images/logo-256x256.png" alt="" class="logo main footer" />
        </a>
        <div class="secondary-logo-container">
          <div class="logo-container footer">
            <a href="https://cme-mec.ca/" target="_blank" class="footer-logo-link w-inline-block">
              <div class="presented-by-text" i18n="Label preceding an image of a logo">Powered by:</div>
              <img *ngIf="locale === 'en'" src="assets/images/2018-CME-Logo-with-word-mark-ENG-1.png" class="presented-by-logo footer" />
              <img *ngIf="locale === 'fr'" src="assets/images/MEC Logo FR.png" class="presented-by-logo footer" />
            </a>
          </div>
        </div>
      </div>
      <div class="footer-menu">
        <div class="label" i18n="Menu header">Menu</div>
        <div class="links-section-footer">
          <a routerLink="/" aria-current="page" class="footer-link w--current" i18n="Menu item">Home</a>
          <a routerLink="/products" class="footer-link" i18n="Menu item">Find Products</a>
          <a routerLink="/consumers" class="footer-link" i18n="Menu item">Consumers</a>
          <a routerLink="/manufacturers" class="footer-link" i18n="Menu item">Manufacturers</a>
          <a routerLink="/retailers" class="footer-link" i18n="Menu item">Retailers</a>
          <a routerLink="/companies" class="footer-link" i18n="Menu item">Ontario Made Companies</a>
          <a routerLink="/blog" class="footer-link" i18n="Menu item">Blog</a>
          <a
            href="https://cme-mec.ca/manufacturing-matters/"
            target="_blank"
            class="footer-link"
            i18n="Menu item - CME is the acronym for a proper noun, Manufacturing Matters is a slogan they use."
            >CME - Manufacturing Matters</a
          >
        </div>
      </div>
      <div class="footer-help">
        <div class="label" i18n="Menu header">Help</div>
        <div class="links-section-footer">
          <a routerLink="/user-agreement" class="footer-link" i18n="Menu item">User Agreement</a>
          <a routerLink="/contact" class="footer-link" i18n="Menu item">Contact Us</a>
        </div>
      </div>
    </div>
    <div class="social-container">
      <a
        href="https://www.facebook.com/SupportOntarioMade/"
        target="_blank"
        class="link-block footer social w-inline-block"
      >
        <img src="assets/images/icons8-facebook-64.png" alt="" class="logo main footer social" />
      </a>
      <a href="https://www.instagram.com/ontario_made/" target="_blank" class="link-block footer social w-inline-block">
        <img src="assets/images/icons8-instagram-64.png" alt="" class="logo main footer social" />
      </a>
      <a
        href="https://www.linkedin.com/showcase/ontario-made"
        target="_blank"
        class="link-block footer social w-inline-block"
      >
        <img src="assets/images/icons8-linkedin-2-64.png" alt="" class="logo main footer social" />
      </a>
      <a href="https://twitter.com/ontario_made" target="_blank" class="link-block footer social w-inline-block">
        <img src="assets/images/icons8-twitter-64.png" alt="" class="logo main footer social" />
      </a>
    </div>
  </div>
</div>
<div class="text-block" i18n="Disclaimer. Canadian Manufacturers & Exporters is a proper noun.">
  The views expressed on this website are the views of Canadian Manufacturers &amp; Exporters.
</div>
