import { Component, ElementRef, Inject, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { MailSubscriptionService } from '@moose/domain/mail-subscription/mail-subscription.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { EventBusService } from '../../../event-bus.service';
import { CookieService } from 'ngx-cookie';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  @ViewChild('mailSubscriptionForm') subscriptionForm!: ElementRef;

  public submitting = false;
  public form: FormGroup;
  public error = false;
  public success = false;

  constructor(
    private fb: FormBuilder,
    private service: MailSubscriptionService,
    private router: Router,
    private cookieService: CookieService,
    private events: EventBusService,
    @Inject(LOCALE_ID) public locale: string,
  ) {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.reset();
    this.router.events.subscribe((val) => {
      this.reset();
    });
    this.events.events$.subscribe((event) => {
      if (event.type === 'SCROLL_TO_MAIL_SUBSCRIPTION') {
        this.subscriptionForm.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    });
  }

  reset() {
    this.form.reset();
    this.form.get('email')?.enable();
    this.success = false;
    this.error = false;
    this.submitting = false;
  }

  onSubmit() {
    if (this.form.valid) {
      const lang = this.cookieService.get('lang') || 'EN';
      this.submitting = true;
      this.form.get('email')?.disable();
      this.service
        .post(
          '',
          JSON.stringify({
            email: this.form.get('email')?.value,
            lang: lang.toUpperCase(),
          })
        )
        .subscribe(
          (res) => {
            this.reset();
            this.success = true;
          },
          (error) => {
            this.submitting = false;
            this.success = false;
            this.error = true;
          }
        );
    } else {
      this.error = true;
    }
  }
}
