import { Injectable } from '@angular/core';
import { AbstractEntityService } from '@moose/domain/abstract/abstract-entity.service';
import { HttpClient } from '@angular/common/http';
import { RetailOutlet } from './retail-outlet';

@Injectable()
export class RetailOutletRegisterService extends AbstractEntityService<RetailOutlet> {
  constructor(http: HttpClient) {
    super('/api/register/retailer', http);
  }
}
