import { Component, OnInit } from '@angular/core';
import { Angulartics2GoogleAnalytics } from 'angulartics2/ga';
import { AnalyticsService } from '@moose/shared/services/analytics.service';

@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  title = 'moose-ui';

  constructor(
    private readonly analyticsService: AnalyticsService,
    private readonly angularticsGoogleService: Angulartics2GoogleAnalytics
  ) {}

  ngOnInit() {
    this.analyticsService.init();
    this.angularticsGoogleService.startTracking();
  }
}
