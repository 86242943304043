<div class="utility-page-wrap">
  <div class="_404-wrap">
    <div class="_404-content-wrap">
      <div class="heading-jumbo" i18n="Page not found header">Page Not Found</div>
      <div class="paragraph-bigger cc-bigger-light" i18n="Page not found further details">The page you are looking for doesn't exist or has been moved.</div>
    </div>
    <a href="/" class="button cc-white-button w-inline-block">
      <div i18n="Button label on the 'page not found' page to return them to the site proper">Go Back to Safety</div>
    </a>
  </div>
</div>
