import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractEntityService } from '@moose/domain/abstract/abstract-entity.service';
import { MailSubscription } from './mail-subscription';

@Injectable()
export class MailSubscriptionService extends AbstractEntityService<MailSubscription> {
  constructor(http: HttpClient) {
    super('/api/mail-subscriptions', http);
  }
}
