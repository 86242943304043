import { NgModule } from '@angular/core';
import { RetailOutletService } from './retail-outlet.service';
import { RetailOutletRegisterService } from '@moose/domain/retail-outlet/retail-outlet-register.service';
import { RetailOutletLocationService } from '@moose/domain/retail-outlet/retail-outlet-location.service';
import { ProductRetailOutletService } from '@moose/domain/retail-outlet/product-retail-outlet.service';
import { ExistingRetailOutletEmailValidator } from '@moose/domain/retail-outlet/retail-outlet-email.validator';

@NgModule({
  providers: [
    RetailOutletService,
    ProductRetailOutletService,
    RetailOutletRegisterService,
    RetailOutletLocationService,
    ExistingRetailOutletEmailValidator
  ],
})
export class RetailOutletModule {}
