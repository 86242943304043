import { Injectable } from '@angular/core';
import { AbstractEntityService } from '@moose/domain/abstract/abstract-entity.service';
import { HttpClient } from '@angular/common/http';
import { RetailOutletLocation } from '@moose/domain/retail-outlet/retail-outlet-location';

@Injectable()
export class RetailOutletLocationService extends AbstractEntityService<RetailOutletLocation> {
  constructor(http: HttpClient) {
    super('/api/retail-outlet-locations', http);
  }

  getByRetailOutletId(retailOutletId: number) {
    return this.http.get<Array<RetailOutletLocation>>(
      this.apiUrl + '/api/retail-outlets/' + retailOutletId + '/locations'
    );
  }

  saveByRetailOutletId(retailOutletId: number, retailOutletLocation: RetailOutletLocation) {
    return this.http.post(this.apiUrl + this.basePath + '/' + retailOutletId, retailOutletLocation);
  }
}
