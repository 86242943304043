import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { httpInterceptorProviders } from './http-interceptors';
import { HttpClientModule } from '@angular/common/http';
import { EventBusService } from './event-bus.service';
import { Angulartics2Module } from 'angulartics2';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookieModule } from 'ngx-cookie';
import { PublicLayoutModule } from '@moose/layouts/public/public-layout.module';
import { AgmCoreModule } from '@agm/core';
import { ToastrModule } from 'ngx-toastr';
import { MailSubscriptionModule } from '@moose/domain/mail-subscription/mail-subscription.module';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    PublicLayoutModule,
    HttpClientModule,
    AppRoutingModule,
    MailSubscriptionModule,
    CookieModule.forRoot(),
    Angulartics2Module.forRoot({
      developerMode: !environment.production,
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapsApiKey,
    }),
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
  ],
  providers: [
    httpInterceptorProviders,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    EventBusService,
  ],
  exports: [ReactiveFormsModule, FormsModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
