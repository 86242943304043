import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { captureException, Scope } from '@sentry/angular';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((response: HttpErrorResponse) => {
        // log to Sentry
        const scope = new Scope();
        scope.setContext('data', {
          method: request.method,
          url: request.url,
          status: response.status,
          message: response.message,
          response: JSON.stringify(response.error),
          request: request.body,
        });
        captureException(new Error('HTTP ' + request.method + ':' + response.status + ' -> ' + request.url), scope);
        // re-throw error
        return throwError(response);
      })
    );
  }
}
