import { AbstractControl, AsyncValidator, ValidationErrors } from '@angular/forms';
import { RetailOutletService } from '@moose/domain/retail-outlet/retail-outlet.service';
import { Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, first, map, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class ExistingRetailOutletEmailValidator implements AsyncValidator {
  constructor(private service: RetailOutletService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.valueChanges) {
      return of(null);
    } else {
      return control.valueChanges
        .pipe(
          debounceTime(200),
          distinctUntilChanged(),
          switchMap((email) => this.service.emailAvailable(email)),
          map((isEmailAvailable: boolean) => {
            if (!isEmailAvailable) {
              return { emailNotAvailable: true };
            }
            return null;
          })
        )
        .pipe(first());
    }
  }
}
