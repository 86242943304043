<div class="message warning">
  <div class="message-title" i18n="Navbar header">CALLING ALL MANUFACTURERS</div>
  <div
    class="message-body"
    i18n="Short explination of the site, including an inline link to our register products page."
  >
    Promote your Ontario made goods. <a routerLink="/register-products" class="link-2">Register your products</a> and
    use the Ontario Made logo.
  </div>
  <img
    src="assets/images/close.png"
    id="close-message"
    data-w-id="53022dc7-b641-5d50-5184-12ccce0aae8b"
    alt=""
    class="close"
  />
</div>
<div data-collapse="medium" data-animation="default" data-duration="400" role="banner" class="navigation w-nav">
  <div class="navigation-items">
    <div id="leftnavigation" class="nav-left">
      <a id="leftnavcontents" routerLink="/" aria-current="page" class="navlogolink w-inline-block w--current">
        <img *ngIf="showLogo" src="assets/images/logo-256x256.png" alt="" class="logo main" />
      </a>
      <a
        *ngIf="!showMenuInXs"
        routerLink="/"
        aria-current="page"
        class="navigation-item home-link w-nav-link w--current"
        i18n="Navbar link home"
        >Home</a
      >
      <a
        *ngIf="!showMenuInXs && locale === 'en'"
        href="https://cme-mec.ca/ontario-made-manufacturing-day"
        aria-current="page"
        class="navigation-item home-link w-nav-link w--current"
        i18n
        style="font-weight: 700;"
      >MANUFACTURING DAY</a
      >
      <a
        *ngIf="!showMenuInXs && locale === 'fr'"
        routerLink="/ontario-manufacturing-day"
        aria-current="page"
        class="navigation-item home-link w-nav-link w--current"
        i18n
        style="font-weight: 700;"
      >MANUFACTURING DAY</a
      >
    </div>
    <div class="navigation-wrap">
      <app-search-box (searchInXsViewport)="toggleLogo($event)" [xsViewport]="xsViewport"></app-search-box>
      <nav [attr.data-nav-menu-open]="showMenuInXs ? '' : null" role="navigation" class="navigation-items w-nav-menu">
        <a
          *ngIf="showMenuInXs"
          [ngClass]="{ mobile: showMenuInXs }"
          routerLink="/"
          class="navigation-item w-nav-link"
          i18n="Navbar link home"
          >Home</a
        >
        <a
          *ngIf="showMenuInXs && locale === 'en'"
          [ngClass]="{ mobile: showMenuInXs }"
          href="https://cme-mec.ca/ontario-made-manufacturing-day"
          style="font-weight: 700;"
          class="navigation-item w-nav-link"
          i18n="Navbar link home"
        >MANUFACTURING DAY</a
        >
        <a
          *ngIf="showMenuInXs && locale === 'fr'"
          [ngClass]="{ mobile: showMenuInXs }"
          routerLink="/ontario-manufacturing-day"
          style="font-weight: 700;"
          class="navigation-item w-nav-link"
          i18n="Navbar link home"
        >MANUFACTURING DAY</a
        >
        <div class="dropdown w-dropdown" style="width: 100%">
          <div
            (click)="showLearnMore = !showLearnMore"
            [ngClass]="{ 'w--nav-dropdown-toggle-open': showMenuInXs }"
            class="navigation-item w-dropdown-toggle"
            id="w-dropdown-toggle-0"
            aria-controls="w-dropdown-list-0"
            aria-haspopup="menu"
            aria-expanded="false"
            role="button"
            tabindex="0"
          >
            <div class="icon-2 w-icon-dropdown-toggle" style="right: 20px"></div>
            <div i18n>Learn More</div>
          </div>
          <nav
            [ngClass]="{ 'w--nav-dropdown-list-open': showMenuInXs }"
            [ngStyle]="{ display: showLearnMore ? 'block' : 'none' }"
            class="dropdown-list w-dropdown-list"
          >
            <a
              routerLink="/products"
              routerLinkActive="w--current"
              class="dropdown-link w-dropdown-link"
              i18n
            >
              Explore Products
            </a>
            <a
              routerLink="/consumers"
              routerLinkActive="w--current"
              class="dropdown-link w-dropdown-link"
              i18n
            >
              Consumers
            </a>
            <a
              routerLink="/info/manufacturers"
              routerLinkActive="w--current"
              class="dropdown-link w-dropdown-link"
              i18n
            >
              Manufacturers
            </a>
            <a
              routerLink="/info/retailers"
              routerLinkActive="w--current"
              class="dropdown-link w-dropdown-link"
              i18n
            >
              Retailers
            </a>
            <a
              routerLink="/companies"
              routerLinkActive="w--current"
              class="dropdown-link w-dropdown-link"
              i18n
            >
              Membership Directory
            </a>
            <a
              routerLink="/blog"
              routerLinkActive="w--current"
              class="dropdown-link w-dropdown-link"
              i18n
            >
              Blog
            </a>
            <a
              routerLink="/partners"
              routerLinkActive="w--current"
              class="dropdown-link w-dropdown-link"
              i18n
            >
              Partners
            </a>
            <a
              href="https://cme-mec.ca/manufacturing-matters/"
              target="_blank"
              class="dropdown-link w-dropdown-link"
              tabindex="0"
              i18n
              >About CME
            </a>
          </nav>
        </div>
        <a
          [ngClass]="{ mobile: showMenuInXs }"
          routerLink="/contact"
          class="navigation-item w-nav-link"
          i18n
          >Contact</a
        >
        <a
          [ngClass]="{ mobile: showMenuInXs }"
          href="/login"
          class="navigation-item w-nav-link"
          i18n
          >Login</a
        >
        <a
          *ngIf="locale === 'en'"
          [ngClass]="{ mobile: showMenuInXs }"
          href="javascript:;"
          (click)="switchLanguage('fr', 'en')"
          class="navigation-item w-nav-link"
          >Français</a
        >
        <a
          *ngIf="locale === 'fr'"
          [ngClass]="{ mobile: showMenuInXs }"
          href="javascript:;"
          (click)="switchLanguage('en', 'fr')"
          class="navigation-item w-nav-link"
          >English</a
        >
      </nav>
      <div
        (click)="toggleXsMenu()"
        class="menu-button w-nav-button"
        style="-webkit-user-select: text"
        aria-label="menu"
        role="button"
        tabindex="0"
      >
        <img src="assets/images/menu-icon_1menu-icon.png" alt="" class="menu-icon" width="22px" />
      </div>
    </div>
  </div>
</div>
