import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MailSubscriptionService } from '@moose/domain/mail-subscription/mail-subscription.service';

@NgModule({
  imports: [CommonModule],
  providers: [MailSubscriptionService],
})
export class MailSubscriptionModule {
}
