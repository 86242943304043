import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-public-layout',
  encapsulation: ViewEncapsulation.None,
  template: '<app-navbar></app-navbar><router-outlet></router-outlet><app-footer></app-footer>',
  styleUrls: [
    '../../../assets/css/search.css',
    '../../../assets/css/normalize.css',
    '../../../assets/css/webflow.css',
    '../../../assets/css/supportontariomade.webflow.css',
    '../../../styles.css',
  ],
})
export class PublicLayoutComponent {
}
