import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { Observable } from 'rxjs';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  private authCookieName = 'X-AUTH-TOKEN';
  private authHeaderName = 'X-AUTH-TOKEN';

  constructor(private cookieService: CookieService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    if (this.cookieService.get(this.authCookieName)) {
      const clonedReq = req.clone({
        headers: req.headers.set(this.authHeaderName, this.cookieService.get(this.authCookieName)),
      });
      return next.handle(clonedReq);
    }
    return next.handle(req);
  }
}
