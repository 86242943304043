// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  siteName: 'Ontario Made',
production: true,
  siteUrl: 'https://supportontariomade.ca',
  serverTimezone: 'America/New_York',
  apiHost: 'https://api.supportontariomade.ca',
  s3BucketUrl: 'https://s3.ca-central-1.amazonaws.com/media.supportontariomade.ca',
  sentryDsn: 'https://453aeab035d44d9cbf4d84aa3547e1a5@o1030395.ingest.sentry.io/6031893',
  googleAnalyticsTrackingID: 'UA-172324344-1',
  googleMapsApiKey: 'AIzaSyBtRYSFT3WXlXAjAIXvHd7MHYes84TZIRY',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
