<form
  [ngClass]="{ sitesearchbox: style === 'small', search: style === 'large' }"
  class="w-form"
  style="position: relative"
>
  <div
    *ngIf="showPopup"
    id="unibox-suggest-box"
    class="unibox unibox--active"
    role="listbox"
    aria-label="Search Suggestions"
    [ngStyle]="{ 'max-width.px': style === 'large' ? 940 : 276, 'min-width.px': style === 'large' ? 940 : 276 }"
    style="
      padding: 5px;
      margin-top: 0px;
      color: rgb(51, 51, 51);
      background-color: rgb(255, 255, 255);
      border: 1px solid rgb(221, 221, 221);
      border-radius: 5px;
      left: 0px;
      top: 40px;
      display: block;
    "
  >
    <section
      *ngIf="result?.suggestions"
      class="unibox-n-section unibox__cluster unibox__cluster--ss360querysuggestions unibox__cluster--2-entries unibox__cluster--no-img"
      aria-labelledby="unibox-suggest-cluster-heading-ss360querysuggestions"
    >
      <div
        *ngFor="let querySuggestion of result.suggestions; index as i"
        [ngClass]="{ 'unibox__selectable--active': i === activeSuggestion }"
        (click)="goToQuerySuggestion(querySuggestion)"
        class="unibox__selectable"
        aria-selected="false"
        role="option"
      >
        <div class="unibox__content-container">
          <span class="unibox__search-content" [innerHTML]="highlight(querySuggestion)"></span>
        </div>
        <div class="unibox__ca" role="presentation"></div>
      </div>
    </section>

    <section
      *ngIf="result.hits"
      class="unibox-n-section unibox__cluster unibox__cluster--_ unibox__cluster--6-entries"
    >
      <div
        *ngFor="let product of result.hits; index as i"
        [ngClass]="{ 'unibox__selectable--active': i === activeSuggestion }"
        (click)="gotToSearchSuggestion(product)"
        class="unibox__selectable"
        aria-selected="false"
        role="option"
      >
        <div class="unibox__img-container">
          <img class="unibox__img" src="{{ productImage(product) }}" alt="" aria-hidden="true" role="presentation" />
        </div>
        <div class="unibox__content-container">
          <a
            class="unibox__search-content unibox__search-content--link"
            (click)="gotToSearchSuggestion(product)"
            title="{{ product.name }}"
            [innerHTML]="highlight(product.name)"
          ></a>
        </div>
        <div class="unibox__ca" role="presentation"></div>
      </div>
    </section>
  </div>
  <img
    src="../../../../assets/images/close-24px.svg"
    id="closesitesearch"
    alt=""
    class="closesitesearch"
    [ngStyle]="{ display: showInputInXs ? 'block' : 'none' }"
    (click)="collapseInputInXs()"
  />
  <input
    #inputEl
    *ngIf="style === 'small'"
    [ngModel]="input"
    (keydown)="inputKeyboardEvent($event)"
    (ngModelChange)="inputChanged.next($event)"
    type="search"
    class="sitesearchinput w-input"
    maxlength="256"
    name="query"
    placeholder="I'm looking for..."
    required=""
    style="
      color: rgb(26, 27, 31);
      font-size: 16px;
      border: 1px solid rgb(255, 255, 255);
      border-radius: 50px;
      padding: 8px 8px 8px 32px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM2NjY2NjYiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNSAxNGgtLjc5bC0uMjgtLjI3QzE1LjQxIDEyLjU5IDE2IDExLjExIDE2IDkuNSAxNiA1LjkxIDEzLjA5IDMgOS41IDNTMyA1LjkxIDMgOS41IDUuOTEgMTYgOS41IDE2YzEuNjEgMCAzLjA5LS41OSA0LjIzLTEuNTdsLjI3LjI4di43OWw1IDQuOTlMMjAuNDkgMTlsLTQuOTktNXptLTYgMEM3LjAxIDE0IDUgMTEuOTkgNSA5LjVTNy4wMSA1IDkuNSA1IDE0IDcuMDEgMTQgOS41IDExLjk5IDE0IDkuNSAxNHoiLz48L3N2Zz4=');
      background-repeat: no-repeat;
      background-position: 8px 9px;
      background-size: 22px 22px;
      background-color: rgb(241, 241, 241);
    "
    autocomplete="off"
    aria-label="Search query"
    i18n-placeholder="Placeholder for a search field"
    i18n-aria-label="Accessibility label for a search field"
    role="combobox"
    aria-describedby="unibox-controls-description"
    aria-owns="unibox-suggest-box"
    aria-controls="unibox-suggest-box"
    aria-expanded="true"
  />

  <input
    #inputEl
    *ngIf="style === 'large'"
    [ngModel]="input"
    (keydown)="inputKeyboardEvent($event)"
    (ngModelChange)="inputChanged.next($event)"
    type="search"
    class="search-input w-input"
    maxlength="256"
    name="query"
    placeholder="I'm looking for..."
    required=""
    style="
      color: rgb(26, 27, 31);
      font-size: 16px;
      border: 1px solid rgb(255, 255, 255);
      border-radius: 50px;
      padding: 8px 8px 8px 52px;
      background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiM2NjY2NjYiIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNSAxNGgtLjc5bC0uMjgtLjI3QzE1LjQxIDEyLjU5IDE2IDExLjExIDE2IDkuNSAxNiA1LjkxIDEzLjA5IDMgOS41IDNTMyA1LjkxIDMgOS41IDUuOTEgMTYgOS41IDE2YzEuNjEgMCAzLjA5LS41OSA0LjIzLTEuNTdsLjI3LjI4di43OWw1IDQuOTlMMjAuNDkgMTlsLTQuOTktNXptLTYgMEM3LjAxIDE0IDUgMTEuOTkgNSA5LjVTNy4wMSA1IDkuNSA1IDE0IDcuMDEgMTQgOS41IDExLjk5IDE0IDkuNSAxNHoiLz48L3N2Zz4=');
      background-repeat: no-repeat;
      background-position: 8px 9px;
      background-size: 42px 42px;
      background-color: rgb(241, 241, 241);
    "
    autocomplete="off"
    i18n-placeholder="Placeholder for a search field"
    i18n-aria-label="Accessibility label for a search field"
    aria-label="Search query"
    role="combobox"
    aria-describedby="unibox-controls-description"
    aria-owns="unibox-suggest-box"
    aria-controls="unibox-suggest-box"
    aria-expanded="true"
  />
  <input
    *ngIf="!showInputInXs"
    (click)="expandInputInXs()"
    type="submit"
    value=""
    id="sitesearchbtn"
    class="sitesearchbutton w-button"
  />
</form>
