import { Component, ElementRef, HostListener, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
})
export class NavbarComponent implements OnInit {
  showLearnMore = false;
  showLogo = true;
  xsViewport = false;
  showMenuInXs = false;

  constructor(
    private elementRef: ElementRef,
    private router: Router,
    private cookieService: CookieService,
    @Inject(LOCALE_ID) public locale: string,
    private http: HttpClient
  ) {
    // hide open menu when route is changed
    router.events.subscribe((val) => {
      this.showLearnMore = false;
      this.showMenuInXs = false;
    });
  }

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event: Event): void {
    // hide open menu when on global click outside the menu
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showLearnMore = false;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    this.xsViewport = window.innerWidth <= 1330;
    if (!this.xsViewport) {
      this.showMenuInXs = false;
    }
    this.showLearnMore = false;
  }

  switchLanguage(toLang: string, fromLang: string) {
    this.cookieService.put('lang', toLang);
    this.http.post<string>(environment.apiHost + '/api/lang', toLang.toUpperCase()).subscribe((res) => {
      window.location.href = window.location.href.replace('/' + fromLang + '/', '/' + toLang + '/');
    });
  }

  ngOnInit(): void {
    this.xsViewport = window.innerWidth <= 479;
  }

  toggleLogo(flag: boolean) {
    this.showLogo = !flag;
  }

  toggleXsMenu() {
    this.showMenuInXs = !this.showMenuInXs;
  }
}
