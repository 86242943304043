import { AbstractEntity } from '@moose/domain/abstract/abstract-entity';
import { FileData } from '../file-data/file-data';
import { Company } from '@moose/domain/company/company';
import { Category } from '@moose/domain/category/category';
import { environment } from '../../../environments/environment';
import { CompanyProducts } from '@moose/domain/company/company-products';
import { ProductLine } from '@moose/domain/product/product-line';
import { CustomField } from '../../public/register-manufacturer/custom-field';
import { ProductHit } from '../../public/search-results/product-hit';
import { Note } from '@moose/domain/note/note';

export class Product extends AbstractEntity {
  name!: string;
  slug!: string;
  state!: string;
  types!: string[];
  url!: string;
  description!: string;
  companyId!: number;
  publishedAt?: Date;
  company!: Company;
  productLineId!: number;
  productLine!: ProductLine;
  categories: Array<Category> = [];
  files?: Array<FileData>;
  retailOutlets?: string;
  customFields?: CustomField[];

  notes?: Note[];

  public static hasCategory(product: Product, categoryId: number) {
    return !!product.categories.find((c) => {
      if (c.parentCategoryId) {
        return c.parentCategoryId === categoryId || c.id === categoryId;
      } else {
        return c.id === categoryId;
      }
    });
  }

  public static imageUrl(product: Product, width?: number): string {
    if (product.files && product.files[0]) {
      const fileData = product.files.sort((c1, c2) => {
        return c1.id > c2.id ? -1 : 1;
      })[0];
      if (!width) {
        return environment.s3BucketUrl + '/' + fileData.subDirectory + '/' + fileData.filename;
      } else {
        return (
          environment.s3BucketUrl +
          '/' +
          fileData.subDirectory +
          '/' +
          fileData.basename +
          '-p-' +
          width +
          '.' +
          fileData.extension
        );
      }
    }
    return 'assets/images/NoProductImage.png';
  }

  public static imageUrlForProductHit(product: ProductHit, width?: number): string {
    if (product.imageSubDirectory == null || product.imageBasename == null || product.imageExtension == null) {
      return 'assets/images/NoProductImage.png';
    } else if (!width) {
      return (
        environment.s3BucketUrl +
        '/' +
        product.imageSubDirectory +
        '/' +
        product.imageBasename +
        '.' +
        product.imageExtension
      );
    } else {
      return (
        environment.s3BucketUrl +
        '/' +
        product.imageSubDirectory +
        '/' +
        product.imageBasename +
        '-p-' +
        width +
        '.' +
        product.imageExtension
      );
    }
  }

  public static route(product: Product): string {
    return '/ontario-made-products/' + product.slug;
  }

  public static routeForSlug(slug: string): string {
    return '/ontario-made-products/' + slug;
  }

  /**
   * Collect companies and their products from flat products list
   *
   * @private
   */
  public static extractProductCompanies(products: Array<Product>, companies: Array<CompanyProducts>) {
    products.forEach((product) => {
      let company = companies.find((c) => c.companyId === product.companyId);
      if (company) {
        company.products.push(product);
      } else {
        company = {
          companyId: product.companyId,
          company: product.company,
          products: [product],
        };
        companies.push(company);
      }
    });
  }

  static getCustomField(name: string, product: Product) {
    return product.customFields?.find((cf) => cf.name === name)?.value;
  }

  static stateName(state: string) {
    switch (state) {
      case 'PENDING':
        return 'Pending';
      case 'PUBLISHED':
        return 'Approved';
      case 'ARCHIVED':
        return 'Archived';
      case 'DISCARDED':
        return 'Discarded';
      default:
        return '';
    }
  }
}
