import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from '@moose/shared/shared.module';
import { SearchModule } from '../../public/search/search.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PublicLayoutComponent } from '@moose/layouts/public/public-layout.component';
import { FooterComponent } from '@moose/layouts/public/footer/footer.component';
import { ErrorPageComponent } from '@moose/layouts/public/error-page/error-page.component';
import { NavbarComponent } from '@moose/layouts/public/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { RetailOutletModule } from '@moose/domain/retail-outlet/retail-outlet.module';
import { CategoryModule } from '@moose/domain/category/category.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    SearchModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    RetailOutletModule,
    CategoryModule,
    GooglePlaceModule,
  ],
  declarations: [NavbarComponent, FooterComponent, ErrorPageComponent, PublicLayoutComponent],
  exports: [NavbarComponent, FooterComponent, ErrorPageComponent, PublicLayoutComponent],
})
export class PublicLayoutModule {}
