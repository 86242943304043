import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EventObject } from './event-object';

@Injectable()
export class EventBusService {
  eventsStream = new Subject<EventObject>();
  events$: Observable<EventObject> = this.eventsStream.asObservable();

  add(event: EventObject) {
    console.log('Adding event', event);
    this.eventsStream.next(event);
  }
}
